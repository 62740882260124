// components
import Section from 'src/components/Section';
import DataBlock from 'src/components/DataBlock';
// hooks
import useBusinessInfoData from 'src/cms/data/business/useBusinessInfoData';

const Info = () => {
  // hooks
  const { dataList } = useBusinessInfoData();

  return (
    <Section>
      <DataBlock type='business' items={dataList} />
    </Section>
  );
};

export default Info;
