// extracted by mini-css-extract-plugin
export var animatedText = "DataBlock-module--animatedText--6d828";
export var block = "DataBlock-module--block--b9ce9";
export var body = "DataBlock-module--body--7ef4f";
export var business_block = "DataBlock-module--business_block--17bd1";
export var content = "DataBlock-module--content--4dca1";
export var icon = "DataBlock-module--icon--26873";
export var image = "DataBlock-module--image--632ec";
export var root = "DataBlock-module--root--5c1bd";
export var title = "DataBlock-module--title--66414";
export var wrapper = "DataBlock-module--wrapper--82bbf";
export var yellow_arrow = "DataBlock-module--yellow_arrow--8d761";