import { FC, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
// components
import Typography from 'src/components/Typography/Typography';
import Icon from 'src/components/Icon';
// contexts
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { drawSvg } from 'src/animations/svg';

import * as style from './DataBlock.module.scss';
import type { Props } from './DataBlock.interface';

const imageHeight = 60;

const DataBlock: FC<Props> = ({ type = 'default', items }) => {
  // contexts
  const { isPageLoaded } = useContext(SitePreloaderContext);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    items.forEach((item, index) => {
      const drawingElement = document.getElementById(`${item.icon}${index}`);

      if (drawingElement && isPageLoaded) {
        const animation = drawSvg(drawingElement).pause();

        onHTMLElementEnterOnWindow(drawingElement, () => {
          animation.play();
        });
      }
    });
  }, [items, isPageLoaded]);

  return (
    <div className={style.root}>
      {items.map((item, index) => (
        <div
          key={index}
          className={classNames(style.block, {
            [style.business_block]: type === 'business',
          })}
        >
          <div className={style.wrapper}>
            <div className={style.content}>
              {item.icon && (
                <div className={style.icon} id={`${item.icon}${index}`}>
                  <Icon name={item.icon} />
                </div>
              )}
              {item.image && (
                <div className={style.image}>
                  <GatsbyImage
                    alt='block_item'
                    image={item.image}
                    style={{
                      height: imageHeight,
                      width: imageHeight * item.image.aspectRatio,
                    }}
                  />
                </div>
              )}
              {item.title && (
                <Typography
                  variant='h2Number'
                  className={classNames(style.title, style.animatedText)}
                >
                  {item.title}
                </Typography>
              )}

              <Typography
                variant='h5'
                className={classNames(style.body, style.animatedText)}
              >
                {item.body}
              </Typography>
            </div>
            <Icon className={style.yellow_arrow} name='yellowArrow' />
          </div>
        </div>
      ))}
    </div>
  );
};

export default DataBlock;
